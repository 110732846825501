import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalVariable {
  public static companyname: string;
  public static inboundHeaderInfo: object;
  public loggedIn: boolean;
}
